import React from 'react';
import { Redirect } from 'react-router-dom';
import Routes from '../../Routing/Routes';

type Props = {
  holding: Object,
  urlPath: String,
};

const Home = ({ holding, urlPath }: Props) => {
  return (
    <>
      {holding && holding.organisations[0] && (
        <Redirect
          to={`${Routes.organisationHomePath(holding.organisations[0])}/${urlPath || ''}`}
        />
      )}
    </>
  );
};

export default Home;
